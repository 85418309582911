// src/pages/DataAnalytics.js

import React from 'react';
import './DataAnalytics.css';

const DataAnalytics = () => {
  return (
    <div className="data-analytics">
      <h1>Data Analytics Course</h1>
      <p>
        Aravind Info Solutions Softwares Training Institute offers a comprehensive Data Analytics <br/>
        course designed to equip you with the skills and knowledge required to excel in the field of <br/>
        data analytics. Our course covers the entire data analytics process, from data collection and <br/>
        cleaning to analysis and visualization.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Data Analytics</li>
        <li>Data Collection and Cleaning</li>
        <li>Statistical Analysis</li>
        <li>Data Visualization</li>
        <li>Exploratory Data Analysis (EDA)</li>
        <li>Predictive Analytics</li>
        <li>Machine Learning Basics</li>
        <li>Real-World Data Analytics Projects</li>
        <li>Hands-on Exercises and Assignments</li>
      </ul>

      <h2>Why Choose Our Data Analytics Course?</h2>
      <ul>
        <li>Experienced and Certified Trainers</li>
        <li>Comprehensive Curriculum</li>
        <li>Hands-on Learning Approach</li>
        <li>Industry-Relevant Projects</li>
        <li>Job Assistance and Interview Guidance</li>
        <li>Flexible Class Timings</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is ideal for:
      </p>
      <ul>
        <li>Aspiring Data Analysts</li>
        <li>Data Science Enthusiasts</li>
        <li>Professionals looking to upskill</li>
        <li>Students pursuing a career in data analytics</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Data Analytics course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default DataAnalytics;
