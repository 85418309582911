// src/pages/CCPJava.js

import React from 'react';
import './CCPJava.css';

const CCPJava = () => {
  return (
    <div className="ccpjava">
      <h1>C, C++, and Java Programming Course</h1>
      <p>
        Welcome to the C, C++, and Java Programming course offered by Aravind Info Solutions Softwares Training Institute.<br/> 
        This course covers three essential programming languages used widely in software development. It provides a <br/>
        comprehensive introduction to each language, focusing on their syntax, features, and applications.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to C programming: syntax, data types, control structures</li>
        <li>Advanced C concepts: pointers, arrays, functions, file handling</li>
        <li>Introduction to C++: object-oriented programming, classes, inheritance, polymorphism</li>
        <li>Advanced C++ features: templates, exceptions, STL (Standard Template Library)</li>
        <li>Introduction to Java: basics, object-oriented concepts, classes, and objects</li>
        <li>Java programming: inheritance, interfaces, packages, exception handling</li>
        <li>Java collections framework: lists, sets, maps</li>
        <li>Multi-threading and concurrency in Java</li>
        <li>GUI (Graphical User Interface) programming with Java Swing or JavaFX</li>
      </ul>

      <h2>Why Choose Our C, C++, and Java Programming Course?</h2>
      <ul>
        <li>Experienced instructors with expertise in C, C++, and Java</li>
        <li>Hands-on coding exercises and projects</li>
        <li>Real-world applications and case studies</li>
        <li>Preparation for programming certification exams</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Beginners new to programming and software development</li>
        <li>Students pursuing computer science or IT-related degrees</li>
        <li>Software developers looking to learn or enhance their skills in C, C++, and Java</li>
        <li>Professionals seeking to transition to software development roles</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the C, C++, and Java Programming course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default CCPJava;
