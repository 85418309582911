// src/pages/DataEngineering.js

import React from 'react';
import './DataEngineering.css';

const DataEngineering = () => {
  return (
    <div className="data-engineering">
      <h1>Data Engineering Course</h1>
      <p>
        Welcome to the Data Engineering course offered by Aravind Info Solutions Softwares Training Institute. <br/>
        This course is designed to equip you with the skills and knowledge required to manage and process large-scale <br/>
        data efficiently.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Data Engineering and its role in data ecosystems</li>
        <li>Data ingestion techniques and tools</li>
        <li>Big Data technologies: Hadoop, Spark, and their ecosystems</li>
        <li>Data storage solutions: relational databases, NoSQL databases, and data warehouses</li>
        <li>Data modeling and schema design</li>
        <li>Data pipeline orchestration and workflow management</li>
        <li>ETL (Extract, Transform, Load) processes and best practices</li>
        <li>Data quality and data governance</li>
        <li>Real-time data processing and streaming technologies</li>
      </ul>

      <h2>Why Choose Our Data Engineering Course?</h2>
      <ul>
        <li>Expert-led training with experienced data engineers</li>
        <li>Hands-on labs and project-based learning</li>
        <li>Practical skills development with industry-standard tools like Apache Kafka, Apache Airflow, etc.</li>
        <li>Certification preparation and exam guidance</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Software engineers transitioning to data engineering roles</li>
        <li>Data analysts looking to advance their skills in data management and processing</li>
        <li>IT professionals interested in Big Data technologies</li>
        <li>Students pursuing careers in data engineering</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Data Engineering course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default DataEngineering;
