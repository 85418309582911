import React, { useState } from 'react';
import './ContactForm.css';

// Updated submitEnquiry function using fetch with timeout and improved error handling
const fetchWithTimeout = (url, options, timeout = 20000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    )
  ]);
};

const submitEnquiry = async (formData) => {
  const url = 'https://ara.usa2everywhere.org/api/submit-enquiry';
  console.log('Submitting to URL:', url); // Logging the URL

  try {
    const response = await fetchWithTimeout(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server responded with:', errorText);
      throw new Error(`Server responded with status ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error in submitEnquiry:', error);
    throw new Error(`Failed to submit enquiry: ${error.message}`);
  }
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    course: '',
    trainingMethod: '',
    message: '',
  });
  const [submitMessage, setSubmitMessage] = useState('');
  const [isMinimized, setIsMinimized] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage(''); // Clear previous messages
    try {
      const data = await submitEnquiry(formData);
      setSubmitMessage('Thanks for sharing details. We will get back soon.');
      setFormData({
        name: '',
        mobile: '',
        course: '',
        trainingMethod: '',
        message: '',
      });
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setSubmitMessage(`Error submitting enquiry: ${error.message}`);
    }
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleReopen = () => {
    setIsVisible(true);
    setIsMinimized(false);
  };

  return (
    <div>
      {!isVisible && (
        <button className="reopen-btn" onClick={handleReopen}>
          Reopen Contact Form
        </button>
      )}
      {isVisible && (
        <div className={`contact-form ${isMinimized ? 'minimized' : ''}`}>
          <div className="contact-form-header">
            <button className="minimize-btn" onClick={handleMinimize}>
              {isMinimized ? '+' : '-'}
            </button>
            <button className="close-btn" onClick={handleClose}>
              x
            </button>
          </div>
          {!isMinimized && (
            <>
              <h2>Quick Enquiry</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile No (WhatsApp)"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                <select
                  name="course"
                  value={formData.course}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select a Course
                  </option>
                  <option value="Data Analytics">Data Analytics</option>
                  <option value="Machine learning & AI">Machine learning & AI</option>
                  <option value="Chatgpt & AI Tools">Chatgpt & AI Tools</option>
                  <option value="Cyber Security">Cyber Security</option>
                  <option value="Data engineering">Data engineering</option>
                  <option value="Cloud-AWS, Azure, GCP">Cloud-AWS, Azure, GCP</option>
                  <option value="Hadoop & Spark">Hadoop & Spark</option>
                  <option value="Python">Python</option>
                  <option value="Scala">Scala</option>
                  <option value="C, C++, Java">C, C++, Java</option>
                  <option value="Android development">Android development</option>
                  <option value="Website development">Website development</option>
                  <option value="MS Office">MS Office</option>
                  <option value="College Projects">College Projects</option>
                </select>
                <select
                  name="trainingMethod"
                  value={formData.trainingMethod}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    How & Where??
                  </option>
                  <option value="Classroom training">Classroom training</option>
                  <option value="Online training">Online training</option>
                </select>
                <textarea
                  name="message"
                  placeholder="Type your message here"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                <button type="submit">Submit</button>
              </form>
              {submitMessage && (
                <div className="submit-message">{submitMessage}</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
