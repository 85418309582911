// src/pages/Cloud.js

import React from 'react';
import './Cloud.css';

const Cloud = () => {
  return (
    <div className="cloud">
      <h1>Cloud Computing with AWS, Azure, and GCP</h1>
      <p>
        Welcome to the Cloud Computing course offered by Aravind Info Solutions Softwares Training Institute. <br/>
        This course covers the fundamentals and advanced concepts of cloud computing, focusing on Amazon Web Services <br/>
        (AWS), Microsoft Azure, and Google Cloud Platform (GCP).
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Cloud Computing and its benefits</li>
        <li>Overview of AWS, Azure, and GCP services and solutions</li>
        <li>Cloud deployment models: public, private, and hybrid clouds</li>
        <li>Virtualization and containerization technologies</li>
        <li>Cloud storage solutions and data management</li>
        <li>Networking and security in the cloud</li>
        <li>Identity and access management (IAM)</li>
        <li>Serverless computing and microservices architecture</li>
        <li>Monitoring, logging, and performance optimization</li>
      </ul>

      <h2>Why Choose Our Cloud Computing Course?</h2>
      <ul>
        <li>Expert instructors with extensive experience in AWS, Azure, and GCP</li>
        <li>Hands-on labs and real-world case studies</li>
        <li>Certification preparation for AWS Certified Solutions Architect, Azure Administrator, and GCP Professional Cloud Architect</li>
        <li>Career guidance and job placement support</li>
        <li>Access to cloud environments and industry-standard tools</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>IT professionals interested in cloud technologies</li>
        <li>System administrators and network engineers</li>
        <li>Developers seeking to deploy applications in the cloud</li>
        <li>Students pursuing careers in cloud computing</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Cloud Computing course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default Cloud;
