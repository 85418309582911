// src/pages/AndroidDevelopment.js

import React from 'react';
import './AndroidDevelopment.css';

const AndroidDevelopment = () => {
  return (
    <div className="android-development">
      <h1>Android Development Course</h1>
      <p>
        Welcome to the Android Development course offered by Aravind Info Solutions Softwares Training Institute. <br/>
        This course is designed to equip you with the skills and knowledge required to build robust and user-friendly <br/>
        Android applications.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Android development and its ecosystem</li>
        <li>Setup and installation of Android Studio</li>
        <li>Fundamentals of Java programming for Android</li>
        <li>User interface design with XML and Android Views</li>
        <li>Activities and fragments: building interactive components</li>
        <li>Data storage and retrieval: SQLite, SharedPreferences</li>
        <li>Networking and web services: Retrofit, Volley</li>
        <li>Working with APIs and JSON data</li>
        <li>Introduction to Firebase for real-time databases and authentication</li>
        <li>Testing and debugging Android applications</li>
        <li>Deploying apps to Google Play Store</li>
      </ul>

      <h2>Why Choose Our Android Development Course?</h2>
      <ul>
        <li>Experienced instructors with Android development expertise</li>
        <li>Hands-on coding projects and real-world applications</li>
        <li>Guidance on best practices and industry standards</li>
        <li>Preparation for Android certification exams (if applicable)</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Beginners new to mobile app development</li>
        <li>Java developers interested in Android app development</li>
        <li>Students pursuing careers in mobile app development</li>
        <li>Professionals looking to expand their skills to Android development</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Android Development course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default AndroidDevelopment;
