// src/pages/Python.js

import React from 'react';
import './Python.css';

const Python = () => {
  return (
    <div className="python">
      <h1>Python Programming Course</h1>
      <p>
        Welcome to the Python Programming course offered by Aravind Info Solutions Softwares Training Institute. <br/>
        This course is designed to introduce you to the fundamentals of Python programming and prepare you for <br/>
        real-world applications in software development, data analysis, web development, and more.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Python and its features</li>
        <li>Basic syntax and data types in Python</li>
        <li>Control flow statements: loops and conditionals</li>
        <li>Functions and modules in Python</li>
        <li>Error handling and debugging techniques</li>
        <li>File handling and working with directories</li>
        <li>Object-oriented programming (OOP) concepts</li>
        <li>Python libraries and frameworks: NumPy, Pandas, Flask, Django</li>
        <li>Database interaction with Python: SQLite, MySQL</li>
        <li>Introduction to data visualization with Matplotlib and Seaborn</li>
      </ul>

      <h2>Why Choose Our Python Course?</h2>
      <ul>
        <li>Experienced instructors with practical industry experience</li>
        <li>Hands-on coding exercises and projects</li>
        <li>Real-world applications and case studies</li>
        <li>Preparation for Python certification exams</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Beginners new to programming and Python</li>
        <li>Software developers looking to enhance their Python skills</li>
        <li>Data analysts and scientists interested in Python for data manipulation and analysis</li>
        <li>Web developers wanting to build web applications using Python frameworks</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Python Programming course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default Python;
