import React from 'react';
import './Footer.css';
import logo from '../assets/logo.png'; // Update the path as needed

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <img src={logo} alt="Aravind Info Solutions" className="logo1" />
          <p>
            Aravind Info Solutions is an outstanding Online Training and Classroom Training,
            Corporate Training Institute with State of Art infrastructure led by the finest trainers in the market.
          </p>
        </div>
        <div className="footer-section social-media">
          <h4>SOCIAL MEDIA</h4>
          <ul>
            <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">YouTube</a></li>
            <li><a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          </ul>
        </div>
        <div className="footer-section contact-details">
          <h4>CONTACT DETAILS</h4>
          <ul>
            <li><i className="fas fa-map-marker-alt"></i> BannerghattaRd, Opp to BGS National School,MuthurayyaSwamyLayout,Hulimavu.</li>
            <li><i className="fas fa-phone"></i> +91 8884752389 (WhatsApp Message/Call)</li>
            <li><i className="fas fa-envelope"></i> aravindtech.bglr@gmail.com</li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Aravind Info Solutions. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;