// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2>About Us:</h2>
      <p>Aravind Info Solutions Softwares is one of the best software training institutes in Bangalore located at <br/> 
        Bannerghatta Road.We are teaching all courses from basic to advanced concepts. We are having dedicated trainers<br/>  
        for all software trending courses in IT industry, and they can spend enough time with students.</p>
      
      <h2>Our Services:</h2>
      <ul>
        <li>Off-Line Training at Marathahalli</li>
        <li>On-Line Training</li>
        <li>Corporate Training</li>
        <li>Job Support</li>
        <li>100% Interview Support</li>
      </ul>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Demo & First 2 free classes</li>
        <li>Classroom & Online</li>
        <li>Real Time training & Project</li>
        <li>Assignment and Case Studies</li>
        <li>Resume Building & Interview Guidance</li>
        <li>Mock Interviews</li>
        <li>100% Job Assistance (Freshers & Experienced)</li>
      </ul>
    </div>
  );
};

export default AboutUs;
