// src/pages/Scala.js

import React from 'react';
import './Scala.css';

const Scala = () => {
  return (
    <div className="scala">
      <h1>Scala Programming Course</h1>
      <p>
        Welcome to the Scala Programming course offered by Aravind Info Solutions Softwares Training Institute. <br/>
        Scala is a powerful programming language known for its functional and object-oriented features. This course <br/>
        will introduce you to Scala and prepare you to leverage its capabilities for building scalable and high-performance <br/>
        applications.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Scala and its features</li>
        <li>Functional programming concepts: immutability, higher-order functions</li>
        <li>Object-oriented programming in Scala</li>
        <li>Scala syntax and basic data types</li>
        <li>Pattern matching and case classes</li>
        <li>Collections and higher-order functions in Scala</li>
        <li>Concurrency and parallel programming with Akka</li>
        <li>Building scalable applications with Scala</li>
        <li>Integration with Java and interoperability</li>
        <li>Scala frameworks: Play Framework, Akka HTTP</li>
      </ul>

      <h2>Why Choose Our Scala Course?</h2>
      <ul>
        <li>Experienced instructors with Scala expertise</li>
        <li>Hands-on coding exercises and projects</li>
        <li>Real-world applications and case studies</li>
        <li>Preparation for Scala certification exams</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Software developers interested in functional programming</li>
        <li>Java developers looking to learn Scala for scalable applications</li>
        <li>Data engineers and scientists interested in Spark (built with Scala)</li>
        <li>Professionals looking to advance their skills in high-performance computing</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Scala Programming course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default Scala;
