// src/pages/ClassroomTraining.js

import React from 'react';
import './ClassroomTraining.css';

const ClassroomTraining = () => {
  return (
    <div className="classroom-training">
      <h1>Classroom Training</h1>
      <p>
        Aravind Info Solutions Softwares Training Institute provides the best specialized Computer Training <br/> 
         on real time projects with well experienced faculties. We provide Classroom training and Online Training <br/> 
         on various IT courses.We are providing online software training based on specific needs of the students.<br/> 
         Aravind Info Solutions Softwares Training Institute provides the best specialized Software Training on real <br/> 
         time projects with well experienced faculties.We provide Online Training, Corporate Training or Classroom Training <br/> 
         on various Software courses.We are providing Online software training based on specific needs of the students and <br/> 
         IT Professionals.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Demo & First 2 free classes</li>
        <li>Classroom & Online</li>
        <li>Real Time training & Project</li>
        <li>Assignment and Case Studies</li>
        <li>Resume Building & Interview Guidance</li>
        <li>Mock Interviews</li>
        <li>100% Job Assistance (Freshers & Experienced)</li>
        <li>10+ Years of experienced & Working Trainers</li>
      </ul>
    </div>
  );
};

export default ClassroomTraining;
