import React from 'react';
import './MSOffice.css'; // Import CSS file

const MSOffice = () => {
  return (
    <div className="ms-office">
      <h1>Microsoft Office Course</h1>
      <p>
        Welcome to the Microsoft Office course offered by Aravind Info Solutions Softwares Training Institute.<br/>  
        This course is designed to provide you with comprehensive knowledge and practical skills in using Microsoft Office <br/> 
        applications for productivity and business purposes.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Microsoft Office suite and its applications</li>
        <li>Microsoft Word: creating documents, formatting text, and using templates</li>
        <li>Microsoft Excel: working with spreadsheets, formulas, and data analysis</li>
        <li>Microsoft PowerPoint: creating presentations, adding animations and transitions</li>
        <li>Microsoft Outlook: managing emails, contacts, and calendars</li>
        <li>Microsoft Access (optional): database management and query design</li>
        <li>Integration and collaboration features across Office applications</li>
        <li>Advanced tips and tricks for efficient use of Office tools</li>
        <li>Customization and automation using macros and VBA (Visual Basic for Applications)</li>
      </ul>

      <h2>Why Choose Our Microsoft Office Course?</h2>
      <ul>
        <li>Experienced instructors with proficiency in Microsoft Office</li>
        <li>Hands-on exercises and practical assignments</li>
        <li>Real-world applications and business scenarios</li>
        <li>Preparation for Microsoft Office Specialist (MOS) certifications</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Office workers and administrative staff</li>
        <li>Students pursuing degrees in business or administration</li>
        <li>Professionals seeking to enhance their productivity skills</li>
        <li>Anyone preparing for Microsoft Office certification exams</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Microsoft Office course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default MSOffice;
