import React from 'react';
import './WebsiteDevelopment.css'; // Import your CSS file

const WebsiteDevelopment = () => {
  return (
    <div className="website-development"> {/* Apply the CSS class to the wrapping div */}
      <h1>Website Development Course</h1>
      <p>
        Welcome to the Website Development course offered by Aravind Info Solutions Softwares Training Institute.<br/> 
        This course is designed to teach you the fundamental skills and technologies required to build modern and<br/>  
        responsive websites.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to web development and its ecosystem</li>
        <li>HTML5: structuring web pages with semantic markup</li>
        <li>CSS3: styling web pages and creating responsive designs</li>
        <li>JavaScript: programming fundamentals and DOM manipulation</li>
        <li>Introduction to frontend frameworks: React.js, Angular, or Vue.js</li>
        <li>Backend development with Node.js or PHP</li>
        <li>Database integration: MySQL, MongoDB, or Firebase</li>
        <li>RESTful APIs and web services</li>
        <li>Version control with Git and GitHub</li>
        <li>Deployment and hosting options</li>
      </ul>

      <h2>Why Choose Our Website Development Course?</h2>
      <ul>
        <li>Experienced instructors with web development expertise</li>
        <li>Hands-on projects and practical assignments</li>
        <li>Guidance on best practices and industry standards</li>
        <li>Exploration of popular frontend and backend frameworks</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Beginners new to web development</li>
        <li>Students pursuing careers in frontend or backend development</li>
        <li>Professionals looking to enhance their web development skills</li>
        <li>Entrepreneurs or business owners interested in creating their websites</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Website Development course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default WebsiteDevelopment;
